const USER_TOKEN_KEY = 'jwt';


export default {
  get: function (key) {
    const item = localStorage.getItem(key);
    return JSON.parse(item);
  },

  set: function (key, object) {
    try {
      const json = JSON.stringify(object);
      localStorage.setItem(key, json);
    } catch (e) {
    }
  },

  setToken: function (token) {
    this.set(USER_TOKEN_KEY, token);
  },

  getToken: function () {
    return this.get(USER_TOKEN_KEY);
  },

  clearStorage: function () {
    localStorage.clear();
  }
};
