import { resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
// interface translations
import englishMessages from 'ra-language-english';
import germanMessages from 'ra-language-german';

// domain translations
import {
    CustomGermanTranslations,
    CustomEnglishTranslations
} from "./index";

const messages = {
    de: { ...germanMessages, ...CustomGermanTranslations },
    en: { ...englishMessages, ...CustomEnglishTranslations },
};

const i18nProvider = polyglotI18nProvider(locale => {

    if(locale === "en") {
        return messages["en"];
    }

    if(locale === "de") {
        messages["de"].ra.navigation.skip_nav = 'Skip to content';
        messages["de"].ra.sort = {
            sort_by: 'Sort by %{field} %{order}',
            ASC: 'ascending',
            DESC: 'descending'
        }
        return messages["de"];
    }
    
}, resolveBrowserLocale());
export default i18nProvider
