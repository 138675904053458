import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  ShowButton,
  EditButton,
  useTranslate
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

const LicenceModelsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="custom.common.search" source="name" alwaysOn />
  </Filter>
);

const Aside = () => {
  const translate = useTranslate();
  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">{translate("custom.licence_models.aside.delete")}</Typography>
      <Typography variant="body2" component="span">
        <ul>
          <li>
            {translate("custom.licence_models.aside.delete_text_1")}
          </li>
        </ul>
      </Typography>
    </div>
  )
};

export const LicenceModelsList = props => {
  return (
    <List
      {...props}
      aside={<Aside />}
      filters={<LicenceModelsFilter />}
      title={"Lizenzmodelle"}
      bulkActionButtons={false} // several API endpoints must be implemented before this can work
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="description" />
        <TextField source="flag" />
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
}
