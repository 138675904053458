import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import {
  BooleanInput,
  DateInput,
  Edit,
  ListButton,
  NumberInput,
  SimpleForm,
  TextInput,
  TopToolbar,
  Toolbar,
  SaveButton,
  useNotify,
  useRedirect,
  useTranslate,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import API from "../../lib/api";
import HTTP from '../../lib/http';
import AddUsersButton from "./AddUsersButton";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontSize: "13px"
  },
  label: {
    paddingLeft: "0.5rem"
  }
}));

const ConfirmOrganizationButton = ({ record, basePath, redirect, resource }) => {
  const redirectTo = useRedirect();
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();

  const confirmOrganization = useCallback((path) => {
    HTTP.post(path)
      .then((response) => {
        notify(translate("custom.organizations.confirm_organization.notify"), 'info', {});
        redirectTo(redirect, basePath, response.data[pluralize.singular(resource)].id, response.data[pluralize.singular(resource)]);
      })
      .catch(error => console.log(error));
  },
    [resource, redirect, notify, redirectTo, basePath]);

  return <Button
    color="primary"
    variant="text"
    classes={{
      root: classes.root,
      label: classes.label
    }}
    startIcon={<CheckIcon />}
    onClick={() => confirmOrganization(API.organizations.confirm(record.id))}>{translate("custom.organizations.confirm_organization.confirmButton")}</Button>;
};

const OrganizationTopToolbar = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
      <AddUsersButton record={data} />
      {data && data.confirmed_at == null &&
        <ConfirmOrganizationButton
          basePath={basePath}
          record={data}
          resource={resource}
          redirect="list" />}
    </TopToolbar>
  );
};

const OrganizationToolbar = props => (
  <Toolbar {...props} >
    <SaveButton />
  </Toolbar>
);

const OrganizationTitle = ({ record }) => {
  return <span>{record ? `${record.name} - ${record.sector}` : ''}</span>
}


const transform = data => ({
  ...data,
  licence_models_id: data.licence_model.id
});

export const OrganizationEdit = props => (
  <Edit
    {...props}
    transform={transform}
    undoable={false}
    actions={<OrganizationTopToolbar />}
    title={<OrganizationTitle />}
  >
    <SimpleForm toolbar={<OrganizationToolbar />}>
      <TextInput source="name" />
      <TextInput source="sector" />
      <DateInput source="end_date" />
      <NumberInput source="max_user_count" />
      <ReferenceInput
        source="licence_model.id"
        reference="licence_models">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);
