import React from 'react';
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  EditButton,
  ShowButton,
  DeleteButton,
  Filter,
  TextInput,
  useTranslate
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

const UsersFilter = (props) => (

  <Filter {...props}>
    <TextInput label="custom.common.search" source="email" alwaysOn />
  </Filter>
);

const Aside = () => {
  const translate = useTranslate();
  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">{translate("custom.users.aside.delete")}</Typography>
      <Typography variant="body2" component="span">
        <ul>
          <li>
            {translate("custom.users.aside.delete_text_1")}
          </li>
          <li>
            {translate("custom.users.aside.delete_text_2")}
          </li>
          <li>
            {translate("custom.users.aside.delete_text_3")}
          </li>
        </ul>
      </Typography>
    </div>
  )
};


export const UserList = props => {


  return (
    <List
      {...props}
      aside={<Aside />}
      filters={<UsersFilter />}
      bulkActionButtons={false} // several API endpoints must be implemented before this can work
    >
      <Datagrid>
        <EmailField source="email" />
        <TextField source="first_name" />
        <TextField source="last_name" />
        <EditButton />
        <ShowButton />
        <DeleteButton
          mutationMode="pessimistic" />
      </Datagrid>
    </List>);
};
