import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
    Edit,
    TextInput,
    ListButton,
    TopToolbar,
    TabbedForm,
    FormTab,
    ArrayInput,
    SimpleFormIterator,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    FormDataConsumer
} from 'react-admin';
import { Field } from 'react-final-form';

const UserTopToolbar = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
        </TopToolbar>
    );
};


const UserTitle = ({ record }) => {
    return <span>{record ? `${record.first_name} - ${record.last_name}` : ''}</span>
}

const FullNameField = ({ record }) => <span>{record.name} - {record.sector}</span>;

const RenderAppSetting = ({ source, record }) => {
    switch (record.type) {
        case "Boolean":
            return <BooleanInput
                record={record}
                key={record.key}
                label={record.key}
                source={source} />
        default:
            return <span>Not supported</span>
    }
}

export const UserEdit = props => (
    <Edit
        {...props}
        undoable={false}
        actions={<UserTopToolbar />}
        title={<UserTitle />}
    >
        <TabbedForm>
            <FormTab label="custom.users.tabs.main_facts">
                <TextInput source="first_name" />
                <TextInput source="last_name" />
            </FormTab>
            <FormTab label="custom.users.tabs.organizations">
                <ArrayInput label="" source="role_group">
                    <SimpleFormIterator>
                        <ReferenceInput
                            source="resource_id"
                            reference="organizations">
                            <SelectInput
                                optionText={<FullNameField />}
                                disabled
                                options={{ disabled: true, readOnly: true }} />
                        </ReferenceInput>
                        <ReferenceInput
                            source="role_id"
                            reference="roles">
                            <SelectInput
                                optionText="name" />
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="custom.users.tabs.app_settings">
                <ArrayInput label="" source="settings.android">
                    <SimpleFormIterator disableRemove disableAdd>
                        <FormDataConsumer>
                            {({
                                formData, // The whole form data
                                scopedFormData, // The data for this item of the ArrayInput
                                getSource, // A function to get the valid source inside an ArrayInput
                                ...rest
                            }) => {
                                return <RenderAppSetting
                                    source={getSource('value')}
                                    record={scopedFormData}
                                    {...rest} />
                            }}
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
);