import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
    Show,
    ArrayField,
    ListButton,
    TopToolbar,
    TextField,
    Datagrid,
    SimpleShowLayout
} from 'react-admin';

const LicenceModelTopToolbar = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
        </TopToolbar>
    );
};

const LicenceModelTitle = ({ record }) => {
    return <span>{record ? `${record.name}` : ''}</span>
}

export const LicenceModelsShow = props => (
    <Show
        {...props}
        undoable={"false"}
        actions={<LicenceModelTopToolbar />}
        title={<LicenceModelTitle />}
    >
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="description" />
            <TextField source="flag" />
            <ArrayField label="" source="features">
                <Datagrid>
                    <TextField source="name" />
                    <TextField source="description" />
                    <TextField source="flag" />
                </Datagrid>
            </ArrayField>
        </SimpleShowLayout>
    </Show>
);