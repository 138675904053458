import axios from 'axios';
import { HttpError } from '../http/errors';
import Storage from '../storage';

let instance = axios.create({
  headers: {
    common: {        // can be common or any other method
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  },
  withCredentials: true
});


// Request interceptor
instance.interceptors.request.use(
  (config) => {
    const token = Storage.getToken();
    const newConfig = config;

    // When a 'token' is available set as Bearer token.
    if (token) {
      newConfig.headers.Authorization = `bearer ${token}`;
    }

    return newConfig;
  },
  err => Promise.reject(err)
);

// Response interceptor
instance.interceptors.response.use(
  response => {
    if (response.headers.jwt)
      Storage.setToken(response.headers.jwt);

    return response;
  },
  (error) => {
    const { status, data } = error.response;

    if (status < 200 || status >= 300) {
      return Promise.reject(
        new HttpError(data, status)
      );
    }

    return Promise.reject(error);
  }
);

export default instance;
