import { REACT_APP_API_PATH } from "../constants/uri";

export default {
  auth: {
    validate: REACT_APP_API_PATH + '/auth/validate',
    signIn: REACT_APP_API_PATH + '/auth/sign_in',
  },
  organizations: {
    collection: `${REACT_APP_API_PATH}/organizations`,
    confirm: organizationId => `${REACT_APP_API_PATH}/organizations/${organizationId}/confirm`,
    invitations: organizationId => `${REACT_APP_API_PATH}/organizations/${organizationId}/organization_invitations`,
    resend_invitation: invitationId => `${REACT_APP_API_PATH}/user_invitations/${invitationId}/resend_mail`,
  },
};
