import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
    Create,
    ListButton,
    TextInput,
    TopToolbar,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    FormTab,
    ArrayInput,
    SimpleFormIterator
} from 'react-admin';



const LicenceModelsTopToolbar = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
        </TopToolbar>
    );
};


const LicenceModelsTitle = ({ record }) => {
    return <span>{record ? `${record.name}` : ''}</span>
}

export const LicenceModelsCreate = props => (
    <Create
        {...props}
        undoable={false}
        actions={<LicenceModelsTopToolbar />}
        title={<LicenceModelsTitle />}
    ><TabbedForm>
            <FormTab label="Lizenzmodell">
                <TextInput source="name" />
                <TextInput source="description" />
                <SelectInput source="flag" choices={[
                    { id: 'standard', name: 'Standard' },
                    { id: 'custom', name: 'Custom' },
                    { id: 'archive', name: 'Archiv' }
                ]} />
            </FormTab>
            <FormTab label="Features">
                <ArrayInput label="" source="features">
                    <SimpleFormIterator>
                        <ReferenceInput
                            source="id"
                            reference="features">
                            <SelectInput
                                optionText="name" />
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Create>
);
