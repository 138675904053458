import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { AppBar as RAAppBar } from 'react-admin';
import { LocaleSwitcher } from "../lib/i18nProvider/LocalSwitcher";

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
});

const AppBar = props => {
  const classes = useStyles();
  return (
    <RAAppBar {...props} color="primary">
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <span className={classes.spacer}/>
      <LocaleSwitcher />
    </RAAppBar>
  );
};

export default AppBar;
