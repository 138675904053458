import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
  Edit,
  ListButton,
  SimpleForm,
  TextInput,
  TopToolbar,
  Toolbar,
  SaveButton
} from 'react-admin';


const FeaturesTopToolbar = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

const FeaturesToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

const FeaturesTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ''}</span>
}

export const FeaturesEdit = props => (
  <Edit
    {...props}
    undoable={false}
    actions={<FeaturesTopToolbar />}
    title={<FeaturesTitle />}
  ><SimpleForm toolbar={<FeaturesToolbar />}>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="flag" />
    </SimpleForm>
  </Edit>
);
