import React from 'react';
import {
  Datagrid,
  EmailField,
  List,
  TextField,
  EditButton,
  DeleteButton,
  Filter,
  TextInput
} from 'react-admin';

const UserInvitationsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="custom.common.search" source="email" alwaysOn />
  </Filter>
);

export const UserInvitationsList = props => (
  <List
    {...props}
    filters={<UserInvitationsFilter />}
    bulkActionButtons={false} // several API endpoints must be implemented before this can work
  >
    <Datagrid>
      <EmailField source="email" />
      <TextField source="role" />
      <TextField source="organization_name" />
      <TextField source="organization_sector" />
      <EditButton />
      <DeleteButton mutationMode="pessimistic" />
    </Datagrid>
  </List>
);
