export const CustomEnglishTranslations = {
    resources: {
        users: {
            name: "Employee",
            fields: {
                first_name: "First name",
                last_name: "Last name",
                email: "Email",
                role: {
                    name: "Roles",
                    actions: "Rights"
                },
                resource_id: "Organization",
                role_id: "Role"
            }
        },
        organizations: {
            name: "Organizations",
            fields: {
                id: "ID",
                name: "Name",
                sector: "Sector",
                active: "Active",
                user_count: "Employee count",
                max_user_count: "Max employee count",
                end_date: "End date",
                confirmed_at: "Confirmed at",
                licence_model: {
                    name: "Licence Model",
                    id: "Licence Model"
                }
            }
        },
        user_invitations: {
            name: "User Invitations",
            fields: {
                email: "Email",
                role: "Role",
                organization_name: "Name of the organization",
                organization_sector: "Sector"
            }
        },
        licence_models: {
            name: "Licence Models",
            fields: {
                name: "Name",
                description: "Description",
                flag: "Flag",
                id: "Feature"
            }
        },
        features: {
            name: "Features",
            fields: {
                name: "Name",
                description: "Description",
                flag: "Flag"
            }
        }
    },
    custom: {
        organizations: {
            tabs: {
                main_facts: "Main facts",
                users: "Employees",
                features: "Usable Features"
            },
            organization_invitations: "Invitations",
            invitations: {
                email: "Email",
                role: "Role"
            },
            users: {
                users: "Employee",
                email: "Email",
                first_name: "First name",
                last_name: "Last name"
            },
            licence_model_features: {
                name: "Feature",
                description: "Description",
                flag: "Flag"
            },
            aside: {
                delete: "Deletion",
                delete_text_1: "Currently, an organization cannot be deleted from the system."
            },
            confirm_organization: {
                notify: "Organization confirmed",
                confirmButton: "Confirm"
            }
        },
        users: {
            tabs: {
                main_facts: "Main facts",
                organizations: "Organizations",
                app_settings: "App settings"
            },
            aside: {
                delete: "Deletion",
                delete_text_1: "Projects created by an employee are automatically assigned to the organization admin.",
                delete_text_2: "If the employee was assigned to a project, after deletion the assignment to the creator of the project will fall.",
                delete_text_3: "If there is only one admin in an organization, it cannot be deleted."
            },
            settings: {
                android: {
                    intro_tour_project_list_activity: "Tour through the project list",
                    intro_tour_ar_activity: "Tour through the recording view",
                    intro_tour_ar_point_activity_one: "Tour through the recording process of volume calculation",
                    intro_tour_drawing_fragment: "Tour through the drawing view",
                    intro_tour_maps_fragment: "Tour through the map view",
                    intro_tour_project_comparison_fragment: "Tour through the view for conflict handling of projects",
                    intro_tour_result_fragment_finished_project: "Tour through the detailed view of a completed project",
                    intro_tour_result_fragment_open_project: "Tour through the detailed view of an open project",
                    vis_explain_icon_dialog: "Tooltip for screenshot function",
                    map_hint: "Information window for calling up the map view",
                    result_hint: "Information window for calling the result view",
                    depth_api_not_supported_hint: "Information window for Depth-API Support",
                    connectivity_hint: "Information window for mobile Internet connection",
                    start_line_measurement_without_custom_maplayer_hint: "Information window for line measurement without own map material"
                }
            }
        },
        user_invitations: {
            resend_email:"Resend the invitation",
            email_resent: "Invitation sent out again"
        },
        licence_models: {
            aside: {
                delete: "Deletion",
                delete_text_1: "A license model cannot be deleted at this time. If a license model is no longer needed, the flag must be set from standard to archieve."
            }
        },
        common: {
            backButton: "Back",
            search: "Search"
        },
        sidebar: {
            users: "Employee",
            organizations: "Organizations",
            user_invitations: "User Invitations",
            licence_models: "Licence Models",
            features: "Features",
            roles: "Roles"
        }
    }
}