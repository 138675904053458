import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton
} from 'react-admin';


const FeaturesFilter = (props) => (
  <Filter {...props}>
    <TextInput label="custom.common.search" source="name" alwaysOn />
  </Filter>
);

export const FeaturesList = props => (
  <List
    {...props}
    title={"Features"}
    bulkActionButtons={false} // several API endpoints must be implemented before this can work
    perPage={25}
    filters={<FeaturesFilter />}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="description" />
      <TextField source="flag" />
      <EditButton />
      <DeleteButton mutationMode="pessimistic" />
    </Datagrid>
  </List>
);
