import React from 'react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  Create,
  SimpleForm,
  TextInput,
  TopToolbar,
  ListButton,
} from 'react-admin';

const FeaturesTopToolbar = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

export const FeaturesCreate = props => (
  <Create
    {...props}
    actions={<FeaturesTopToolbar />}
  >
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="description" />
      <TextInput source="flag" />
    </SimpleForm>
  </Create>
);
