import { createMuiTheme } from '@material-ui/core/styles';
import { deDE } from '@material-ui/core/locale';

// COLORS
// Primary
export const primary = '#5953D2';
export const primaryLight = '#8F80FF';
export const primaryDark = '#443F9E';
export const primaryContrastText = '#FFFFFF';

// Secondary
export const secondary = '#853BD1';
export const secondaryLight = '#B96BFF';
export const secondaryDark = '#51009F';
export const secondaryContrastText = '#FFFFFF';

// Error
export const error = '#FF0400';
export const errorContrastText = '#FFFFFF';

// Greyish
export const darkBlueBase = '#1F2633';
export const darkBlueLighter = '#474E5D';
export const darkBlueDarker = '#000000';
export const darkBlueDark = '#3C424D';
export const darkBlueMediumDark = '#868A91';
export const darkBlueMedium = '#AAADB1';
export const darkBlueMediumLight = '#E3E4E5';
export const darkBlueLight = '#F0F1F1';
export const darkBlueSuperLight = '#F8F8F9';
export const darkBlueContrastText = '#FFFFFF';

export default createMuiTheme({
  palette: {
    primary: {
      light: primaryLight,
      main: primary,
      dark: primaryDark,
      contrastText: primaryContrastText
    },
    secondary: {
      light: secondaryLight,
      main: secondary,
      dark: secondaryDark,
      contrastText: secondaryContrastText
    },
    error: {
      light: error,
      main: error,
      dark: error,
      contrastText: errorContrastText
    },
    text: {
      primary: darkBlueDark,
      secondary: darkBlueMediumDark,
      disabled: darkBlueMedium,
      hint: darkBlueContrastText
    }
  }
}, deDE);
