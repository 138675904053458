import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
    Show,
    TabbedShowLayout,
    Tab,
    ListButton,
    TopToolbar,
    TextField,
    EmailField,
    ArrayField,
    Datagrid,
    SingleFieldList,
    ChipField,
    ReferenceField,
    EditButton,
    FunctionField,
    BooleanField,
    useTranslate
} from 'react-admin';
import StringToLabelObject from "../../lib/ui/StringToLabelObject";

const UserTopToolbar = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
        </TopToolbar>
    );
};

const UserTitle = ({ record }) => {
    return <span>{record ? `${record.first_name} - ${record.last_name}` : ''}</span>
}

const AppSettingTitle = ({ record }) => {
    const translate = useTranslate();
    return <span>{translate(`custom.users.settings.android.${record.key}`)}</span>
}

const RenderAppSetting = ({ record }) => {
    switch (record.type) {
        case "Boolean":
            return <BooleanField record={record} source="value" />
        default:
            return <span>Not supported</span>
    }
}

export const UserShow = props => (
    <Show
        {...props}
        undoable={"false"}
        actions={<UserTopToolbar />}
        title={<UserTitle />}
    >
        <TabbedShowLayout>
            <Tab label="custom.users.tabs.main_facts">
                <EmailField source="email" />
                <TextField source="first_name" />
                <TextField source="last_name" />
            </Tab>
            <Tab label="custom.users.tabs.organizations">
                <ArrayField label="" source="role_group">
                    <Datagrid>
                        <ReferenceField
                            source="resource_id"
                            reference="organizations">
                            <FunctionField
                                render={record => `${record.name} - ${record.sector}`} />
                        </ReferenceField>
                        <TextField source="role.name" />
                        <ArrayField source="role.actions">
                            <SingleFieldList linkType={false}>
                                <StringToLabelObject>
                                    <ChipField
                                        source="label" />
                                </StringToLabelObject>
                            </SingleFieldList>
                        </ArrayField>
                        <EditButton />
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="custom.users.tabs.app_settings">
                <ArrayField label="" source="settings.android">
                    <Datagrid>
                        <AppSettingTitle />
                        <RenderAppSetting label="Value" />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);