import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useLocale, useSetLocale } from 'react-admin';

const useStyles = makeStyles((theme) => ({
    root: {
        color: '#ffffff'
    }
}));

export const LocaleSwitcher = () => {
    const locale = useLocale();
    const setLocale = useSetLocale();
    const classes = useStyles();
    return (
        <div>
            <Button 
                disabled={locale === 'en'}
                onClick={() => setLocale('en')}
                classes={{
                    root: classes.root
                }}
            >
                English
            </Button>
            <Button
                disabled={locale === 'de'}
                onClick={() => setLocale('de')}
                classes={{
                    root: classes.root
                }}
            >
                German
            </Button>
        </div>
    );
};