import HTTP from './http';
import Storage from './storage';
import API from './api'

const authProvider = {
  login: async ({ username, password }) => {
    return await HTTP.post(API.auth.signIn, {
      email: username,
      password,
      client: "admin_panel"
    });
  },
  logout: () => {
    Storage.clearStorage();
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: async () => {
    return await HTTP.get(API.auth.validate, null);
  },
  getPermissions: () => Promise.reject('Unknown method')
};

export default authProvider;
