import ChevronLeft from '@material-ui/icons/ChevronLeft';
import CheckIcon from '@material-ui/icons/Check';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import pluralize from 'pluralize';
import React, { useCallback } from 'react';
import {
    Edit,
    ListButton,
    TopToolbar,
    Toolbar,
    SaveButton,
    useNotify,
    useRedirect,
    useTranslate,
    SelectInput,
    SimpleForm
} from 'react-admin';
import API from "../../lib/api";
import HTTP from '../../lib/http';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: "4px",
        paddingBottom: "4px",
        paddingLeft: "5px",
        paddingRight: "5px",
        fontSize: "13px"
    },
    label: {
        paddingLeft: "0.5rem"
    }
}));

const ResendInvitationButton = ({ record, basePath, redirect, resource }) => {
    const redirectTo = useRedirect();
    const notify = useNotify();
    const classes = useStyles();
    const translate = useTranslate();

    const confirmOrganization = useCallback((path) => {
        HTTP.post(path)
            .then((response) => {
                notify(translate("custom.user_invitations.email_resent"), 'info', {});
                redirectTo(redirect, basePath, response.data[pluralize.singular(resource)].id, response.data[pluralize.singular(resource)]);
            })
            .catch(error => console.log(error));
    },
        [resource, redirect, notify, redirectTo, basePath]);

    return <Button
        color="primary"
        variant="text"
        classes={{
            root: classes.root,
            label: classes.label
        }}
        startIcon={<CheckIcon />}
        onClick={() => confirmOrganization(API.organizations.resend_invitation(record.id))}>{translate("custom.user_invitations.resend_email")}</Button>;
};

const UserInvitationsTopToolbar = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
            <ResendInvitationButton
                basePath={basePath}
                record={data}
                resource={resource}
                redirect="list" />
        </TopToolbar>
    );
};

const UserInvitationsToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
  );

const UserInvitationsTitle = ({ record }) => {
    return <span>{record ? `${record.first_name} - ${record.last_name}` : ''}</span>
}

export const UserInvitationsEdit = props => (
    <Edit
        {...props}
        undoable={false}
        actions={<UserInvitationsTopToolbar />}
        title={<UserInvitationsTitle />}
    >
        <SimpleForm toolbar={<UserInvitationsToolbar/>}>
            <SelectInput source="role" choices={[
                { id: 'User', name: 'User' },
                { id: 'Admin', name: 'Admin' }
            ]}/>
        </SimpleForm>
    </Edit>
);