import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Input from "@material-ui/core/Input";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import IconContentAdd from "@material-ui/icons/Add";
import IconAddCircle from '@material-ui/icons/AddCircle';
import IconCancel from "@material-ui/icons/Cancel";
import IconClose from "@material-ui/icons/Close";

import React, { Fragment, useState } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import API from "../../lib/api";
import HTTP from "../../lib/http";

const styles = {
  wrapper: {
    margin: "2rem 0 0 0",
    display: "flex",
    flexWrap: "wrap",
    width: "100%"
  },
  user: {
    display: "flex",
    margin: "1rem 0",
    width: "100%",
    alignItems: "center"
  },
  addRow: {
    display: "flex",
    margin: "1rem auto 0 0",
    alignItems: "center",
    cursor: "pointer"
  },
  horizontalSpacer: {
    width: "1rem"
  }
};

const AddUsersButton = ({ record }) => {
  const [state, setState] = useState({
    error: false,
    showDialog: false,
    users: {
      0: {
        email: '',
        role: 'User',
        valid: false
      }
    }
  });
  const notify = useNotify();
  const refresh = useRefresh();

  const handleClick = () => {
    setState({ ...state, showDialog: true });
  };

  const handleCloseClick = () => {
    setState({ ...state, showDialog: false });
  };

  const handleSaveClick = () => {
    const { users } = state;
    if (emailsValid(users)) {
      console.log(users, Object.values(users));
      HTTP.post(API.organizations.invitations(record.id), { organization_invitations: Object.values(users) })
        .then((response) => {
          notify('User eingeladen', 'info', {});
          handleCloseClick();
          refresh();
        })
        .catch(error => console.log(error));

    } else {
      setState({ ...state, error: true });
    }
  };

  const addNewUser = key => {
    setState({
      ...state,
      users: {
        ...state.users,
        [key + 1]: {
          email: '',
          role: 'User',
          valid: true
        }
      }
    });
  };

  const deleteUser = key => {
    const users = state.users;
    if (Object.keys(users).length === 1) {
      users[key].email = '';
    } else {
      delete users[key];
    }
    setState({
      ...state,
      users
    });
  };

  const onKeyPress = (event, newKey) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      addNewUser(newKey);
    }
  };

  const showInvitedUsers = (newKey, userKeys) => {
    const { users, error } = state;
    return Object.keys(users).map((key, index) => {
      const user = users[key];
      return <div
        style={styles.user} key={key}>
        <Input
          autoFocus={index === userKeys.length - 1}
          name='email'
          onChange={event => onChangeEmail(event, key)}
          onKeyPress={event => onKeyPress(event, newKey)}
          placeholder='E-Mail Adresse...'
          error={error && !user.valid}
          type='email'
          value={user.email}/>
        <div style={styles.horizontalSpacer}/>
        <Select
          label="Rolle"
          value={user.role}
          onChange={event => onChangeRole(event, key)}>
          <MenuItem value={"User"}>User</MenuItem>
          <MenuItem value={"Admin"}>Admin</MenuItem>
        </Select>
        <Button onClick={() => deleteUser(key)}>
          <IconClose/>
        </Button>
      </div>;
    });
  };

  const onChangeEmail = (event, key) => {
    const value = event.target.value.trim();
    const pattern = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    setState({
      ...state,
      users: {
        ...state.users,
        [key]: {
          ...state.users[key],
          email: value,
          valid: value.length === 0 ? true : new RegExp(pattern).test(value)
        }
      }
    });
  };

  const onChangeRole = (event, key) => {
    const value = event.target.value.trim();
    setState({
      ...state,
      users: {
        ...state.users,
        [key]: {
          ...state.users[key],
          role: value
        }
      }
    });
  };

  const emailsValid = (users) => {
    const keys = Object.keys(users);
    return keys.every(key => users[key].valid);
  };


  const { showDialog, users } = state;
  const userKeys = Object.keys(users);
  const newKey = parseInt(userKeys[userKeys.length - 1], 10);
  return (
    <Fragment>
      <Button onClick={handleClick} label="User hinzufügen">
        <IconContentAdd/>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="User hinzufügen"
      >
        <DialogTitle>User hinzufügen</DialogTitle>
        <DialogContent>
          <div style={styles.wrapper}>
            {showInvitedUsers(newKey, userKeys)}
          </div>
          <div style={styles.addRow}
               onClick={() => addNewUser(newKey)}>
            <Button
              color='primary'>
              <IconAddCircle/>
            </Button>
            <Typography
              variant='body2'
              color='primary'>
              Zeile hinzufügen
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button varian="inline" label="ra.action.save"
                  onClick={handleSaveClick}/>
          <Button label="ra.action.cancel" onClick={handleCloseClick}>
            <IconCancel/>
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );

};

export default AddUsersButton;
