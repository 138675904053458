import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import BusinessIcon from '@material-ui/icons/Business';
import PermDeviceInformationIcon from '@material-ui/icons/PermDeviceInformation';
import { Admin, Resource } from 'react-admin';
import Layout from "./layout/Layout";
import { REACT_APP_API_PATH } from './constants/uri';
import authProvider from "./lib/authProvider";
import _dataProvider from './lib/dataProvider';
import i18nProvider from "./lib/i18nProvider/i18nProvider";
import {
  OrganizationCreate,
  OrganizationEdit,
  OrganizationList,
  OrganizationShow
} from "./resources/organizations";
import {
  LicenceModelsList,
  LicenceModelsCreate,
  LicenceModelsShow,
  LicenceModelsEdit
} from "./resources/licence_models";
import {
  FeaturesList,
  FeaturesCreate,
  FeaturesEdit
} from "./resources/features";
import {
  UserList,
  UserShow,
  UserEdit
} from "./resources/users";
import {
  UserInvitationsEdit,
  UserInvitationsList,
  UserInvitationsShow
} from "./resources/user_invitations";
import theme from './theme';

const dataProvider = _dataProvider(REACT_APP_API_PATH);

const App = () => {
  return (
    <Admin layout={Layout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      i18nProvider={i18nProvider}
      theme={theme}>
      <Resource
        name="organizations"
        icon={BusinessIcon}
        options={{ label: 'custom.sidebar.organizations' }}
        list={OrganizationList}
        edit={OrganizationEdit}
        create={OrganizationCreate}
        show={OrganizationShow} />
      <Resource
        name="users"
        options={{ label: 'custom.sidebar.users' }}
        icon={PersonIcon}
        list={UserList}
        show={UserShow}
        edit={UserEdit} />
      <Resource
        name="user_invitations"
        icon={PersonAddIcon}
        options={{ label: 'custom.sidebar.user_invitations' }}
        list={UserInvitationsList}
        edit={UserInvitationsEdit}
        show={UserInvitationsShow} />
      <Resource
        name="licence_models"
        icon={PermDeviceInformationIcon}
        options={{ label: 'custom.sidebar.licence_models' }}
        list={LicenceModelsList}
        show={LicenceModelsShow} 
        edit={LicenceModelsEdit}
        create={LicenceModelsCreate} />
      <Resource
        name="features"
        icon={PermDeviceInformationIcon}
        options={{ label: 'custom.sidebar.features' }}
        list={FeaturesList}
        edit={FeaturesEdit} 
        create={FeaturesCreate}/>
      <Resource
        name="roles"
        options={{ label: 'custom.sidebar.roles' }} />
    </Admin>
  );
};

export default App;
