export const CustomGermanTranslations = {
    resources: {
        users: {
            name: "Mitarbeiter",
            fields: {
                first_name: "Vorname",
                last_name: "Nachname",
                email: "Email",
                role: {
                    name: "Rollen",
                    actions: "Rechte"
                },
                resource_id: "Organisation",
                role_id: "Rolle"
            }
        },
        organizations: {
            name: "Organisationen",
            fields: {
                id: "ID",
                name: "Name",
                sector: "Abteilung",
                active: "Aktive",
                user_count: "Anzahl Mitarbeiter",
                max_user_count: "Max Anzahl an Mitarbeitern",
                end_date: "Enddatum",
                confirmed_at: "Bestätigt am",
                licence_model: {
                    name: "Lizenzmodell",
                    id: "Lizenzmodell"
                },
            }
        },
        user_invitations: {
            name: "Eingeladene Mitarbeiter",
            fields: {
                email: "Email",
                role: "Rolle",
                organization_name: "Name der Organisation",
                organization_sector: "Abteilung"
            }
        },
        licence_models: {
            name: "Lizenzmodelle",
            fields: {
                name: "Name",
                description: "Beschreibung",
                flag: "Flag",
                id: "Feature"
            }
        },
        features: {
            name: "Features",
            fields: {
                name: "Name",
                description: "Beschreibung",
                flag: "Flag"
            }
        }
    },
    custom: {
        organizations: {
            tabs: {
                main_facts: "Eckdaten",
                users: "Mitarbeiter",
                features: "Nutzbare Features"
            },
            organization_invitations: "Einladungen",
            invitations: {
                email: "Email",
                role: "Rolle"
            },
            users: {
                users: "Mitarbeiter",
                email: "Email",
                first_name: "Vorname",
                last_name: "Nachname"
            },
            licence_model_features: {
                name: "Feature",
                description: "Beschreibung",
                flag: "Flag"
            },
            aside: {
                delete: "Löschen",
                delete_text_1: "Aktuell kann eine Organisation nicht vom System gelöscht werden."
            },
            confirm_organization: {
                notify: "Organisation bestätigt",
                confirmButton: "Bestätigen"
            }
        },
        users: {
            tabs: {
                main_facts: "Eckdaten",
                organizations: "Organisationen",
                app_settings: "App Einstellungen"
            },
            aside: {
                delete: "Löschen",
                delete_text_1: "Projekte die von einem Mitarbeiter erstellt wurden, werden automatisch dem Organisations-Admin zugewiesen.",
                delete_text_2: "Wenn der Mitarbeiter einem Projekt zugewiesen wurde, fällt nach dem Löschen die Zuweisung zum Erstellter des Projekts.",
                delete_text_3: "Wenn es in einer Organisation nur einen Admin gibt, kann der nicht gelöscht werden."
            },
            settings: {
                android: {
                    intro_tour_project_list_activity: "Tour durch die Projektliste",
                    intro_tour_ar_activity: "Tour durch die Aufnahmeansicht",
                    intro_tour_ar_point_activity_one: "Tour durch den Aufnahmeprozess der Volumenberechnung",
                    intro_tour_drawing_fragment: "Tour durch die Zeichenansicht",
                    intro_tour_maps_fragment: "Tour durch die Kartenansicht",
                    intro_tour_project_comparison_fragment: "Tour durch die Ansicht für das Konflikthandling von Projekten",
                    intro_tour_result_fragment_finished_project: "Tour durch die Detailansicht eines abgeschlossenen Projekts",
                    intro_tour_result_fragment_open_project: "Tour durch die Detailansicht eines offenen Projekts",
                    vis_explain_icon_dialog: "Tooltip für Screenshotfunktion",
                    map_hint: "Hinweisfenster für Aufruf der Kartenansicht",
                    result_hint: "Hinweisfenster für Aufruf der Ergebnisansicht",
                    depth_api_not_supported_hint: "Hinweisfenster für Depth-API Support",
                    connectivity_hint: "Hinweisfenster bei mobiler Internetverbindung",
                    start_line_measurement_without_custom_maplayer_hint: "Hinweisfenster für Leitungsaufnahme ohne eigenem Kartenmaterial"
                }
            }
        },
        user_invitations: {
            resend_email: "Einladung erneut verschicken",
            email_resent: "Einladung erneut verschickt"
        },
        licence_models: {
            aside: {
                delete: "Löschen",
                delete_text_1: "Ein Lizenzmodell kann derzeit nicht gelöscht werden. Wenn ein Lizenzmodell nicht mehr benötigt wird, muss die Flag von standard auf archieve gesetzt werden."
            }
        },
        common: {
            backButton: "Zurück",
            search: "Suche"
        },
        sidebar: {
            users: "Mitarbeiter",
            organizations: "Organizationen",
            user_invitations: "Eingeladene Mitarbeiter",
            licence_models: "Lizenzmodelle",
            features: "Features",
            roles: "Rollen"
        }
    }
}