import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  BooleanField,
  EditButton,
  ShowButton,
  useTranslate
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

const OrganizationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="custom.common.search" source="name" alwaysOn />
  </Filter>
);

const Aside = () => {
  const translate = useTranslate();
  return (
  <div style={{ width: 200, margin: '1em' }}>
    <Typography variant="h6">{translate("custom.organizations.aside.delete")}</Typography>
    <Typography variant="body2" component="span">
      <ul>
        <li>
        {translate("custom.organizations.aside.delete_text_1")}
          </li>
      </ul>
    </Typography>
  </div>
)};

export const OrganizationList = props => (
  <List
    {...props}
    aside={<Aside />}
    bulkActionButtons={false} // several API endpoints must be implemented before this can work
    perPage={25}
    filters={<OrganizationFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="sector" />
      <BooleanField source="active" />
      <NumberField source="user_count" sortable={false} />
      <NumberField source="max_user_count" />
      <NumberField source="licence_model.name" />
      <DateField source="end_date" />
      <DateField source="confirmed_at" />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);
