import { useMediaQuery } from '@material-ui/core';
import DefaultIcon from '@material-ui/icons/ViewList';
import React from 'react';
import { getResources, MenuItemLink, translate } from 'react-admin';
import { useSelector } from 'react-redux';

const Menu = translate(({ onMenuClick, logout, translate }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  return (
    <div>
      {resources.map(resource => (
        <MenuItemLink
          key={resource.name}
          to={`/${resource.name}`}
          primaryText={
            (resource.options && translate(resource.options.label)) ||
            resource.name
          }
          leftIcon={
            resource.icon ? <resource.icon/> : <DefaultIcon/>
          }
          onClick={onMenuClick}
          sidebarIsOpen={open}
        />
      ))}
      {/*<MenuItemLink
        to="/custom-route"
        primaryText="Miscellaneous"
        leftIcon={<LabelIcon/>}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />*/}
      {isXSmall && logout}
    </div>
  );
});

export default Menu;
