import ChevronLeft from '@material-ui/icons/ChevronLeft';
import React from 'react';
import {
    ArrayField,
    Datagrid,
    Show,
    TabbedShowLayout,
    Tab,
    ListButton,
    TopToolbar,
    TextField,
    DateField,
    NumberField,
    BooleanField,
    ReferenceField
} from 'react-admin';


const OrganizationTopToolbar = ({ basePath, data, resource }) => {
    return (
        <TopToolbar>
            <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
        </TopToolbar>
    );
};

const OrganizationTitle = ({ record }) => {
    return <span>{record ? `${record.name} - ${record.sector}` : ''}</span>
}


export const OrganizationShow = props => (
    <Show
        {...props}
        undoable={"false"}
        actions={<OrganizationTopToolbar />}
        title={<OrganizationTitle />}
    >
        <TabbedShowLayout>
            <Tab label="custom.organizations.tabs.main_facts">
                <TextField source="name" />
                <TextField source="sector" />
                <DateField source="end_date" />
                <NumberField source="max_user_count" />
                <BooleanField source="active" />
                <ReferenceField
                    source="licence_model.id"
                    reference="licence_models">
                    <TextField source="name" />
                </ReferenceField>
            </Tab>
            <Tab label="custom.organizations.tabs.users">
                <ArrayField
                    label='custom.organizations.organization_invitations'
                    source="organization_invitations">
                    <Datagrid>
                        <TextField
                            label="custom.organizations.invitations.email"
                            source="email" />
                        <TextField
                            label="custom.organizations.invitations.role"
                            source="role" />
                    </Datagrid>
                </ArrayField>
                <ArrayField
                    label="custom.organizations.users.users"
                    source="users">
                    <Datagrid>
                        <TextField
                            label="custom.organizations.users.first_name"
                            source="first_name" />
                        <TextField
                            label="custom.organizations.users.last_name"
                            source="last_name" />
                        <TextField
                            label="custom.organizations.users.email"
                            source="email" />
                    </Datagrid>
                </ArrayField>
            </Tab>
            <Tab label="custom.organizations.tabs.features">
                <ArrayField
                    label=""
                    source="licence_model.features">
                    <Datagrid>
                        <TextField
                            label="custom.organizations.licence_model_features.name"
                            source="name" />
                        <TextField
                            label="custom.organizations.licence_model_features.description"
                            source="description" />
                        <TextField
                            label="custom.organizations.licence_model_features.flag"
                            source="flag" />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);