import merge from 'deepmerge';
import pluralize from 'pluralize';
import { stringify } from 'qs';
import http from '../http';
import { NotImplementedError } from '../http/errors';
import {
  CREATE,
  DELETE,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE
} from './actions';

import defaultSettings from './defaultSettings';

export default (apiUrl, userSettings = {}) => (type, resource, params) => {
  let url = '';
  const settings = merge(defaultSettings, userSettings);

  const options = {
    headers: settings.headers
  };

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination;

      // Create query with pagination params.
      const query = {
        'page[number]': page,
        'page[size]': perPage
      };

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        query[`filter[${key}]`] = params.filter[key];
      });

      // Add sort parameter
      if (params.sort && params.sort.field) {
        const prefix = params.sort.order === 'ASC' ? '' : '-';
        query.sort = `${prefix}${params.sort.field}`;
      }

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      break;
    }

    case GET_ONE:
      url = `${apiUrl}/${resource}/${params.id}`;
      break;

    case CREATE:
      url = `${apiUrl}/${resource}`;
      options.method = 'POST';
      options.data = JSON.stringify({
        [pluralize.singular(resource)]: params.data
      });
      break;

    case UPDATE: {
      url = `${apiUrl}/${resource}/${params.id}`;

      const data = { [pluralize.singular(resource)]: params.data };

      options.method = settings.updateMethod;
      options.data = JSON.stringify(data);
      break;
    }

    case DELETE:
      url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;

    case GET_MANY:
      {
        const query = stringify({
          'page[number]': 1,
          'page[size]': 50,
          'filter[id]': params.ids
        }, { arrayFormat: settings.arrayFormat });


        url = `${apiUrl}/${resource}?${query}`;
        console.log(url);
        break;
      }

    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;

      // Create query with pagination params.
      const query = {
        'page[number]': page,
        'page[size]': perPage
      };

      // Add all filter params to query.
      Object.keys(params.filter || {}).forEach((key) => {
        query[`filter[${key}]`] = params.filter[key];
      });

      // Add the reference id to the filter params.
      query[`filter[${params.target}]`] = params.id;

      url = `${apiUrl}/${resource}?${stringify(query)}`;
      console.log(url);
      break;
    }

    default:
      throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
  }

  return http({ url, ...options })
    .then((response) => {
      let total;

      // For all collection requests get the total count.
      if ([GET_LIST, GET_MANY, GET_MANY_REFERENCE].includes(type)) {
        if (response.headers && settings.total) {
          total = parseInt(response.headers[settings.total]);
        }
      }

      switch (type) {
        case GET_MANY:
        case GET_LIST: {
          return {
            data: response.data[resource],
            total
          };
        }

        case GET_MANY_REFERENCE: {
          return {
            data: response.data[resource],
            total
          };
        }

        case GET_ONE: {
          return {
            data: response.data[pluralize.singular(resource)]
          };
        }

        case CREATE: {
          return {
            data: response.data[pluralize.singular(resource)]
          };
        }

        case UPDATE: {
          return {
            data: response.data[pluralize.singular(resource)]
          };
        }

        case DELETE: {
          return {
            data: { id: params.id }
          };
        }

        default:
          throw new NotImplementedError(`Unsupported Data Provider request type ${type}`);
      }
    });
};
