import React from 'react';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import {
  Create,
  SimpleForm,
  DateInput,
  TextInput,
  NumberInput,
  BooleanInput,
  TopToolbar,
  ListButton,
  ReferenceInput,
  SelectInput
} from 'react-admin';

const OrganizationTopToolbar = ({ basePath, data, resource }) => {
  return (
    <TopToolbar>
      <ListButton basePath={basePath} label="custom.common.backButton" icon={<ChevronLeft />} />
    </TopToolbar>
  );
};

const transform = data => ({
  ...data,
  licence_models_id: data.licence_model.id
});

export const OrganizationCreate = props => (
  <Create
    {...props}
    actions={<OrganizationTopToolbar />}
    transform={transform}
  >
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="sector" />
      <DateInput source="end_date" />
      <NumberInput source="max_user_count" />
      <SelectInput source="status" choices={[
        { id: 'active', name: 'Active' },
        { id: 'trailing', name: 'Trailing' },
        { id: 'free', name: 'Free' },
      ]} />
      <ReferenceInput
        source="licence_model.id"
        reference="licence_models">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="active" />
    </SimpleForm>
  </Create>
);
